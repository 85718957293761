<template>
      <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
          <div class="ui-layout-content--full ui-layout-flex--1" style="margin: 0px 20px 20px 20px">
              <ex-search-table-pagination
                  ref="searchTable"
                  :fetch="$client.getPatrolAssignmentList"
                  :columns="columns"
                  :formOptions="formOptions"
                  :searchWhenSortChange="true"
              >
                  <template slot="append">
                      <el-table-column
                          align="center"
                          label="操作"
                          type="action"
                          width="120"
                          fixed="right"
                      >
                          <template slot-scope="scope">
                              <el-tooltip content="作业" placement="top" :open-delay="200">
                                  <div
                                      class="search-table-icon search-table-icon_detail"
                                      @click="addOrUpdateHandle(scope.row)"
                                      type="text"
                                  ></div>
                              </el-tooltip>
                             <!-- <el-tooltip content="删除" placement="top" :open-delay="200">
                                  <div
                                      class="search-table-icon search-table-icon_delete"
                                      @click="deleteHandle(scope.row.id)"
                                      type="text"
                                  ></div>
                              </el-tooltip>-->
                          </template>
                      </el-table-column>
                  </template>
              </ex-search-table-pagination>
          </div>
      <div class="ui-layout-content--full">
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" :patrolResults="patrolResults" :patrolTypes="patrolTypes" :flawLevels="flawLevels" :expTypes="expTypes" :expLevels="expLevels"></add-or-update>
      </div>
  </div>
</template>

<script>
import AddOrUpdate from './patrolAssignment-add-or-update';
import * as dayjs from 'dayjs';
export default {
    data () {
        return {
            patrolTypes: [],
            patrolResults: [],
            flawLevels: [],
            expTypes: [],
            expLevels: [],
            statuss: [{value: 0, name: '待巡检'}, {value: 1, name: '已巡检'}],
            addOrUpdateVisible: false
        };
    },
    components: {
        AddOrUpdate
    },
    computed: {
        columns() {
            return [
                {
                    prop: 'assignmentName',
                    label: '作业任务',
                },
                {
                    prop: 'patrolType',
                    label: '巡检类型',
                    formatter: (row, column, cellValue) => {
                        for (let item of this.patrolTypes) {
                            if (item.value === cellValue) {return item.name;}
                        }
                    },
                },
                {
                    prop: 'maintenanceUnit',
                    label: '运维单位',
                },
                {
                    prop: 'patrolOwner',
                    label: '巡检人',
                },
                {
                    prop: 'createTime',
                    formatter: (row, column, cellValue) => {
                        if (cellValue) {
                            let time = dayjs(cellValue).format('YYYY-MM-DD HH:mm');
                            return time;
                        }
                        return '';
                    },
                    label: '任务创建时间',
                },
                {
                    prop: 'patrolTime',
                    formatter: (row, column, cellValue) => {
                        if (cellValue) {
                            let time = dayjs(cellValue).format('YYYY-MM-DD HH:mm');
                            return time;
                        }
                        return '';
                    },
                    label: '巡检时间',
                },
                {
                    prop: 'status',
                    label: '巡检状态',
                    formatter: (row, column, cellValue) => {
                        for (let item of this.statuss) {
                            if (item.value === cellValue) {return item.name;}
                        }
                    },
                },
                {
                    prop: 'patrolResult',
                    label: '巡检结果',
                    formatter: (row, column, cellValue) => {
                        for (let item of this.patrolResults) {
                            if (item.value === cellValue) {return item.name;}
                        }
                    },
                },
                {
                    prop: 'flawCount',
                    label: '缺陷数'
                },
                {
                    prop: 'failureCount',
                    label: '故障数'
                },
                {
                    prop: 'riskyCount',
                    label: '安全隐患数'
                },
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'patrolType',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择巡检类型',
                        label: '',
                        options: this.patrolTypes,
                        valueKey: 'value',
                        labelKey: 'name',
                    },
                    /* {
                        prop: 'maintenanceUnitId',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择巡检单位',
                        label: '巡检单位：',
                        options: this.unitList,
                        valueKey: 'id',
                        labelKey: 'name',
                    },*/
                    {
                        prop: 'status',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择状态',
                        options: this.statuss,
                        valueKey: 'value',
                        labelKey: 'name',
                    },
                    {
                        prop: 'patrolResult',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择设备巡检结果',
                        options: this.patrolResults,
                        valueKey: 'value',
                        labelKey: 'name',
                    },
                    /*{
                        prop: 'patrolTimes',
                        itemType: 'daterange',
                        clearable: true,
                        filterable: true,
                        startPlaceholder: '开始日期',
                        endPlaceholder: '结束日期',
                    },*/
                    {
                        prop: 'p',
                        itemType: 'date',
                        clearable: true,
                        filterable: true,
                        placeholder: '开始日期'
                    },
                    {
                        prop: 'a',
                        itemType: 'date',
                        clearable: true,
                        filterable: true,
                        placeholder: '结束日期'
                    },

                ],
            };
        }
    },
    created () {
        this.init();
    },
    methods: {
        init() {
            //约定维保组织code以WB开头
            this.$client.getOrgUnitByOrgTypeAndCode({orgType: 'department', code: 'WB'}).then((res) => {
                this.unitList = res.data;
            });
            this.$client.listDictionaryChildrenArr(['patrol_type', 'patrol_result', 'flaw_level', 'patrol_exp_type', 'patrol_exp_level']).then((res) => {
                this.patrolTypes = res.data.patrol_type.map(item => {
                    item.value = parseInt(item.value);
                    return item;
                });
                this.patrolResults = res.data.patrol_result;
                this.flawLevels = res.data.flaw_level;
                this.expTypes = res.data.patrol_exp_type;
                this.expLevels = res.data.patrol_exp_level;
            });
        },
        // 获取数据列表
        getDataList (type) {
            if (type) {
                this.$refs.searchTable.searchHandler();
            }
        },
        // 新增 / 修改
        addOrUpdateHandle (row) {
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(row);
            });
        },
        // 删除
        deleteHandle (id) {
            if (id) {
                this.$confirm('确定进行删除操作?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.$client.delPatrolAssignment(id).then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                        });
                        this.$refs.searchTable.searchHandler();
                    });
                });
            }
        }
    }
};
</script>
