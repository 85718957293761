<template>
    <div class="substation-record">
        <table width="1846.13" border="0" cellpadding="0" cellspacing="0" class="ui-border"
               style="border-collapse:collapse;table-layout:fixed;">
            <tbody>
            <tr height="30" style="height:22.50pt;mso-height-source:userset;mso-height-alt:450;">
                <td height="64" colspan="2" rowspan="2"
                    style="height:48.00pt;width: 120px;">10kV开关柜
                </td>
                <td colspan="18">10kV母线
                </td>
            </tr>
            <tr height="34" style="height:25.50pt;">
                <td>031间隔</td>
                <td>041间隔</td>
                <td>042间隔</td>
                <td>051间隔</td>
                <td>052间隔</td>
                <td>053间隔</td>
                <td>054间隔</td>
                <td>055间隔</td>
                <td>056间隔</td>
                <td>057间隔</td>
                <td><span>058</span><span
                >间隔</span></td>
                <td><span>059</span><span
                >间隔</span></td>
                <td><span>060<span
                >&nbsp;&nbsp;&nbsp; </span></span><span>间隔</span></td>
                <td><span>061</span><span
                >间隔</span></td>
                <td><span>062</span><span
                >间隔</span></td>
                <td><span>063</span><span
                >间隔</span></td>
                <td><span>064</span><span
                >间隔</span></td>
                <td class="xl149"><span>065</span><span
                >间隔</span></td>
            </tr>
            <tr height="20" style="height:15.00pt;">
                <td height="20" colspan="2"
                    style="height:15.00pt;">声音
                </td>
                <td>
                    <el-input v-model="records.v1"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v2"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v3"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v4"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v5"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v6"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v7"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v8"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v9"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v10"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v11"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v12"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v13"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v14"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v15"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v16"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v17"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v18"></el-input>
                </td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td height="20" colspan="2"
                    style="height:15.00pt;">保护装置
                </td>
                <td>
                    <el-input v-model="records.v19"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v20"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v21"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v22"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v23"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v24"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v25"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v26"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v27"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v28"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v29"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v30"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v31"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v32"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v33"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v34"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v35"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v36"></el-input>
                </td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td height="20" colspan="2"
                    style="height:15.00pt;">电气指示
                </td>
                <td>
                    <el-input v-model="records.v37"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v38"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v39"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v40"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v41"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v42"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v43"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v44"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v45"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v46"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v47"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v48"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v49"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v50"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v51"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v52"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v53"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v54"></el-input>
                </td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td height="20" colspan="2"
                    style="height:15.00pt;">
                    电流I（A）
                </td>
                <td>
                    <el-input v-model="records.v55"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v56"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v57"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v58"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v59"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v60"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v61"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v62"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v63"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v64"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v65"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v66"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v67"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v68"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v69"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v70"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v71"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v72"></el-input>
                </td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td height="60" rowspan="3"
                    style="height:45.00pt;">
                    避雷器动作次数
                </td>
                <td>A</td>
                <td>
                    <el-input v-model="records.v73"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v74"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v75"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v76"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v77"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v78"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v79"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v80"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v81"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v82"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v83"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v84"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v85"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v86"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v87"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v88"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v89"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v90"></el-input>
                </td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td>B</td>
                <td>
                    <el-input v-model="records.v91"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v92"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v93"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v94"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v95"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v96"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v97"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v98"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v99"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v100"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v101"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v102"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v103"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v104"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v105"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v106"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v107"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v108"></el-input>
                </td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td>C</td>
                <td>
                    <el-input v-model="records.v109"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v110"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v111"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v112"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v113"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v114"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v115"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v116"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v117"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v118"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v119"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v120"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v121"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v122"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v123"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v124"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v125"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v126"></el-input>
                </td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td height="20" colspan="2"
                    style="height:15.00pt;">
                    开关位置（合/分）
                </td>
                <td>
                    <el-input v-model="records.v127"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v128"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v129"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v130"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v131"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v132"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v133"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v134"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v135"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v136"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v137"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v138"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v139"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v140"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v141"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v142"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v143"></el-input>
                </td>
                <td>
                    <el-input v-model="records.v144"></el-input>
                </td>

            </tr>
            <tr height="53" style="height:39.75pt;">
                <td height="53" colspan="4"
                    style="height:39.75pt;">
                    110kV断路器、隔离开关及接地开关
                </td>
                <td>检查<span>&nbsp; </span>项目</td>
                <td colspan="2"
                    style="">110kV母线
                </td>
                <td colspan="2"
                    style="">110kV进线
                </td>
                <td colspan="2" style=""><span
                >10</span><span>kV母线</span></td>
                <td>检查<span>&nbsp; </span>项目</td>
                <td>1号主变</td>
                <td>1号站用变</td>
                <td>2号站用变</td>
                <td>1号接地变</td>
                <td colspan="2"
                    style="">直流系统
                </td>
                <td>I段</td>
                <td>Ⅱ段</td>
            </tr>
            <tr height="40" style="height:30.00pt;">
                <td height="40" style="height:30.00pt;">编号</td>
                <td>压力(Mpa)</td>
                <td>外观</td>
                <td>状态</td>
                <td>Uab(kV)</td>
                <td colspan="2"
                    style=""><el-input v-model="records.v145"></el-input>
                </td>
                <td colspan="2"
                    style=""><el-input v-model="records.v146"></el-input>
                </td>
                <td colspan="2" style=""><el-input v-model="records.v147"></el-input>
                </td>
                <td>声音</td>
                <td><el-input v-model="records.v148"></el-input></td>
                <td><el-input v-model="records.v149"></el-input></td>
                <td><el-input v-model="records.v150"></el-input></td>
                <td><el-input v-model="records.v151"></el-input></td>

                <td class="xl151" colspan="2"
                    style="">母线电压（V）
                </td>
                <td><el-input v-model="records.v152"></el-input></td>
                <td><el-input v-model="records.v153"></el-input></td>

            </tr>
            <tr height="23" style="height:17.25pt;">
                <td class="xl91" height="23" style="height:17.25pt;">161</td>
                <td><el-input v-model="records.v154"></el-input></td>
                <td><el-input v-model="records.v155"></el-input></td>
                <td><el-input v-model="records.v156"></el-input></td>

                <td>Ubc(kV)</td>
                <td colspan="2"
                    style=""><el-input v-model="records.v157"></el-input>
                </td>
                <td colspan="2"
                    style=""><el-input v-model="records.v158"></el-input>
                </td>
                <td colspan="2" style=""><el-input v-model="records.v159"></el-input>
                </td>
                <td>油色</td>
                <td><el-input v-model="records.v160"></el-input></td>
                <td><el-input v-model="records.v161"></el-input></td>
                <td><el-input v-model="records.v162"></el-input></td>
                <td><el-input v-model="records.v163"></el-input></td>

                <td class="xl151" colspan="2"
                    style="">母线电流（A）
                </td>
                <td><el-input v-model="records.v164"></el-input></td>
                <td><el-input v-model="records.v165"></el-input></td>

            </tr>
            <tr height="23" style="height:17.25pt;">
                <td class="xl91" height="23" style="height:17.25pt;">101</td>
                <td><el-input v-model="records.v166"></el-input></td>
                <td><el-input v-model="records.v167"></el-input></td>
                <td><el-input v-model="records.v168"></el-input></td>

                <td>Uca(kV)</td>
                <td colspan="2"
                    style=""><el-input v-model="records.v169"></el-input>
                </td>
                <td colspan="2"
                    style=""><el-input v-model="records.v170"></el-input>
                </td>
                <td colspan="2" style=""><el-input v-model="records.v171"></el-input>
                </td>
                <td>本体油位</td>
                <td><el-input v-model="records.v172"></el-input></td>
                <td><el-input v-model="records.v173"></el-input></td>
                <td><el-input v-model="records.v174"></el-input></td>
                <td><el-input v-model="records.v175"></el-input></td>

                <td class="xl151" colspan="2"
                    style="">电池电压（V）
                </td>
                <td><el-input v-model="records.v176"></el-input></td>
                <td><el-input v-model="records.v177"></el-input></td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td class="xl93" height="20" colspan="2"
                    style="height:15.00pt;">001
                </td>
                <td><el-input v-model="records.v178"></el-input></td>
                <td><el-input v-model="records.v179"></el-input></td>

                <td class="xl102">COSΦ</td>
                <td class="xl103" colspan="2"
                    style=""><el-input v-model="records.v180"></el-input>
                </td>
                <td class="xl103" colspan="2"
                    style=""><el-input v-model="records.v181"></el-input>
                </td>
                <td class="xl103" colspan="2" style=""><el-input v-model="records.v182"></el-input>
                </td>
                <td>油温（℃）</td>
                <td><el-input v-model="records.v183"></el-input></td>
                <td><el-input v-model="records.v184"></el-input></td>
                <td><el-input v-model="records.v185"></el-input></td>
                <td><el-input v-model="records.v186"></el-input></td>

                <td class="xl152" colspan="2"
                    style="">电池电流（A）
                </td>
                <td><el-input v-model="records.v187"></el-input></td>
                <td><el-input v-model="records.v188"></el-input></td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td class="xl93" height="20" colspan="2"
                    style="height:15.00pt;">16167
                </td>
                <td><el-input v-model="records.v189"></el-input></td>
                <td><el-input v-model="records.v190"></el-input></td>

                <td class="xl104" colspan="3"
                    style="">二次盘柜
                </td>
                <td class="xl105">外观</td>
                <td class="xl105">电源</td>
                <td class="xl105" colspan="2" style="">告警</td>
                <td>端子箱</td>
                <td><el-input v-model="records.v191"></el-input></td>
                <td><el-input v-model="records.v192"></el-input></td>
                <td><el-input v-model="records.v193"></el-input></td>
                <td><el-input v-model="records.v194"></el-input></td>

                <td class="xl153" colspan="2"
                    style="">UPS系统
                </td>
                <td>I段</td>
                <td>Ⅱ段</td>
            </tr>
            <tr height="20" style="height:15.00pt;">
                <td class="xl93" height="20" colspan="2"
                    style="height:15.00pt;">1616
                </td>
                <td><el-input v-model="records.v195"></el-input></td>
                <td><el-input v-model="records.v196"></el-input></td>

                <td class="xl106" colspan="3"
                    style="">110kV线路保护屏
                </td>
                <td><el-input v-model="records.v197"></el-input></td>
                <td><el-input v-model="records.v198"></el-input></td>

                <td colspan="2" style=""><el-input v-model="records.v199"></el-input>
                </td>
                <td>绕温（℃）</td>
                <td><el-input v-model="records.v200"></el-input></td>
                <td><el-input v-model="records.v201"></el-input></td>
                <td><el-input v-model="records.v202"></el-input></td>
                <td><el-input v-model="records.v203"></el-input></td>

                <td colspan="2"
                    style="">输出电压（V）
                </td>
                <td><el-input v-model="records.v204"></el-input></td>
                <td><el-input v-model="records.v205"></el-input></td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td class="xl93" height="20" colspan="2"
                    style="height:15.00pt;">16117
                </td>
                <td><el-input v-model="records.v206"></el-input></td>
                <td><el-input v-model="records.v207"></el-input></td>

                <td class="xl106" colspan="3"
                    style="">110kV线路测控屏
                </td>
                <td><el-input v-model="records.v208"></el-input></td>
                <td><el-input v-model="records.v209"></el-input></td>

                <td colspan="2" style=""><el-input v-model="records.v210"></el-input>
                </td>
                <td>套管</td>
                <td><el-input v-model="records.v211"></el-input></td>
                <td><el-input v-model="records.v212"></el-input></td>
                <td><el-input v-model="records.v213"></el-input></td>
                <td><el-input v-model="records.v214"></el-input></td>

                <td class="xl154" colspan="2" style="">输出电流（A）
                </td>
                <td><el-input v-model="records.v215"></el-input></td>
                <td><el-input v-model="records.v216"></el-input></td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td class="xl93" height="20" colspan="2"
                    style="height:15.00pt;">1611
                </td>
                <td><el-input v-model="records.v217"></el-input></td>
                <td><el-input v-model="records.v218"></el-input></td>

                <td class="xl106" colspan="3"
                    style="">110kV母线保护屏
                </td>
                <td><el-input v-model="records.v219"></el-input></td>
                <td><el-input v-model="records.v220"></el-input></td>

                <td colspan="2" style=""><el-input v-model="records.v221"></el-input>
                </td>
                <td>引线</td>
                <td><el-input v-model="records.v222"></el-input></td>
                <td><el-input v-model="records.v223"></el-input></td>
                <td><el-input v-model="records.v224"></el-input></td>
                <td><el-input v-model="records.v225"></el-input></td>

                <td class="xl155" colspan="4"
                    style="">站用电系统电压（V）
                </td>
            </tr>
            <tr height="20" style="height:15.00pt;">
                <td class="xl93" height="20" colspan="2"
                    style="height:15.00pt;">
                    16160
                </td>
                <td><el-input v-model="records.v226"></el-input></td>
                <td><el-input v-model="records.v227"></el-input></td>

                <td class="xl106" colspan="3"
                    style="">1号主变保护屏
                </td>
                <td><el-input v-model="records.v228"></el-input></td>
                <td><el-input v-model="records.v229"></el-input></td>

                <td colspan="2" style=""><el-input v-model="records.v230"></el-input>
                </td>
                <td>硅胶颜色</td>
                <td><el-input v-model="records.v231"></el-input></td>
                <td><el-input v-model="records.v232"></el-input></td>
                <td><el-input v-model="records.v233"></el-input></td>
                <td><el-input v-model="records.v234"></el-input></td>

                <td class="xl158">I段</td>
                <td><el-input v-model="records.v235"></el-input></td>

                <td class="xl134">II段</td>
                <td><el-input v-model="records.v236"></el-input></td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td class="xl93" height="20" colspan="2"
                    style="height:15.00pt;">19010
                </td>
                <td><el-input v-model="records.v237"></el-input></td>
                <td><el-input v-model="records.v238"></el-input></td>

                <td class="xl107" colspan="3"
                    style="">调度通讯屏
                </td>
                <td><el-input v-model="records.v239"></el-input></td>
                <td><el-input v-model="records.v240"></el-input></td>

                <td class="xl118" colspan="2" style=""><el-input v-model="records.v241"></el-input>
                </td>
                <td>有无渗漏</td>
                <td><el-input v-model="records.v242"></el-input></td>
                <td><el-input v-model="records.v243"></el-input></td>
                <td><el-input v-model="records.v244"></el-input></td>
                <td><el-input v-model="records.v245"></el-input></td>

                <td colspan="4"
                    style="">避雷器动作情况（A、B、C)
                </td>
            </tr>
            <tr height="20" style="height:15.00pt;">
                <td class="xl93" height="20" colspan="2"
                    style="height:15.00pt;">1901
                </td>
                <td><el-input v-model="records.v246"></el-input></td>
                <td><el-input v-model="records.v247"></el-input></td>

                <td class="xl107" colspan="3"
                    style="">录音电话
                </td>
                <td><el-input v-model="records.v248"></el-input></td>
                <td><el-input v-model="records.v249"></el-input></td>

                <td class="xl118" colspan="2" style=""><el-input v-model="records.v250"></el-input>
                </td>
                <td class="xl119">档位</td>
                <td><el-input v-model="records.v251"></el-input></td>
                <td><el-input v-model="records.v252"></el-input></td>
                <td><el-input v-model="records.v253"></el-input></td>
                <td><el-input v-model="records.v254"></el-input></td>

                <td class="xl161"><span class="span6">1</span><span class="span6">10kV线路</span></td>
                <td><el-input v-model="records.v255"></el-input></td>
                <td><el-input v-model="records.v256"></el-input></td>
                <td><el-input v-model="records.v257"></el-input></td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td class="xl93" height="20" colspan="2"
                    style="height:15.00pt;">19017
                </td>
                <td><el-input v-model="records.v258"></el-input></td>
                <td><el-input v-model="records.v259"></el-input></td>

                <td class="xl107" colspan="3"
                    style="">小电流接地选线装置
                </td>
                <td><el-input v-model="records.v260"></el-input></td>
                <td><el-input v-model="records.v261"></el-input></td>

                <td class="xl118" colspan="2" style=""><el-input v-model="records.v262"></el-input>
                </td>
                <td class="xl119">消弧线圈</td>
                <td><el-input v-model="records.v263"></el-input></td>
                <td><el-input v-model="records.v264"></el-input></td>
                <td><el-input v-model="records.v265"></el-input></td>
                <td><el-input v-model="records.v266"></el-input></td>

                <td class="xl161">110kV母线</td>
                <td><el-input v-model="records.v267"></el-input></td>
                <td><el-input v-model="records.v268"></el-input></td>
                <td><el-input v-model="records.v269"></el-input></td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td class="xl93" height="20" colspan="2"
                    style="height:15.00pt;">1011
                </td>
                <td><el-input v-model="records.v270"></el-input></td>
                <td><el-input v-model="records.v271"></el-input></td>

                <td class="xl106" colspan="3"
                    style="">低压低频减载装置
                </td>
                <td><el-input v-model="records.v272"></el-input></td>
                <td><el-input v-model="records.v273"></el-input></td>

                <td colspan="2" style=""><el-input v-model="records.v274"></el-input>
                </td>
                <td class="xl120">#1SVG声音</td>
                <td class="xl138">#1SVG外观</td>
                <td class="xl138">#1SVG状态</td>
                <td class="xl138">蓄电池组一</td>
                <td class="xl139">蓄电池组二</td>
                <td class="xl162">#1主变（高）</td>
                <td><el-input v-model="records.v275"></el-input></td>
                <td><el-input v-model="records.v276"></el-input></td>
                <td><el-input v-model="records.v277"></el-input></td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td class="xl93" height="20" colspan="2"
                    style="height:15.00pt;">10117
                </td>
                <td><el-input v-model="records.v278"></el-input></td>
                <td><el-input v-model="records.v279"></el-input></td>

                <td class="xl106" colspan="3"
                    style="">公用测控屏
                </td>
                <td><el-input v-model="records.v280"></el-input></td>
                <td><el-input v-model="records.v281"></el-input></td>

                <td colspan="2" style=""><el-input v-model="records.v282"></el-input>
                </td>
                <td><el-input v-model="records.v283"></el-input></td>
                <td><el-input v-model="records.v284"></el-input></td>
                <td><el-input v-model="records.v285"></el-input></td>
                <td><el-input v-model="records.v286"></el-input></td>
                <td><el-input v-model="records.v287"></el-input></td>

                <td class="xl163">#1主变（低）</td>
                <td><el-input v-model="records.v288"></el-input></td>
                <td><el-input v-model="records.v289"></el-input></td>
                <td><el-input v-model="records.v290"></el-input></td>

            </tr>
            <tr height="20" style="height:15.00pt;">
                <td class="xl93" height="20" colspan="2"
                    style="height:15.00pt;">1016
                </td>
                <td><el-input v-model="records.v291"></el-input></td>
                <td><el-input v-model="records.v292"></el-input></td>

                <td class="xl106" colspan="3"
                    style="">远动通讯屏
                </td>
                <td><el-input v-model="records.v293"></el-input></td>
                <td><el-input v-model="records.v294"></el-input></td>

                <td colspan="2" style=""><el-input v-model="records.v295"></el-input>
                </td>
                <td class="xl121" colspan="3"
                    style="">二次盘柜
                </td>
                <td class="xl141">外观</td>
                <td class="xl142">电源</td>
                <td class="xl164" colspan="2"
                    style="">告警
                </td>
                <td class="xl165" colspan="2"
                    style="">备注
                </td>
            </tr>
            <tr height="20" style="height:15.00pt;">
                <td class="xl93" height="20" colspan="2"
                    style="height:15.00pt;">10160
                </td>
                <td><el-input v-model="records.v296"></el-input></td>
                <td><el-input v-model="records.v297"></el-input></td>

                <td class="xl106" colspan="3"
                    style="">故障录波屏
                </td>
                <td><el-input v-model="records.v298"></el-input></td>
                <td><el-input v-model="records.v299"></el-input></td>

                <td colspan="2" style=""><el-input v-model="records.v300"></el-input>
                </td>
                <td class="xl122" colspan="3"
                    style="">110kV线路保护智能终端
                </td>
                <td><el-input v-model="records.v301"></el-input></td>
                <td><el-input v-model="records.v302"></el-input></td>

                <td colspan="2"
                    style=""><el-input v-model="records.v303"></el-input>
                </td>
                <td class="xl167" colspan="2" rowspan="3"
                    style=""><el-input type="textarea" :rows="3" v-model="records.v304"></el-input>
                </td>
            </tr>
            <tr height="20" style="height:15.00pt;">
                <td class="xl93" height="20" colspan="2"
                    style="height:15.00pt;">10167
                </td>
                <td><el-input v-model="records.v305"></el-input></td>
                <td><el-input v-model="records.v306"></el-input></td>

                <td class="xl106" colspan="3"
                    style="">电能质量监测屏
                </td>
                <td><el-input v-model="records.v307"></el-input></td>
                <td><el-input v-model="records.v308"></el-input></td>

                <td colspan="2" style=""><el-input v-model="records.v309"></el-input>
                </td>
                <td class="xl122" colspan="3"
                    style="">110kV母线保护智能终端
                </td>
                <td><el-input v-model="records.v310"></el-input></td>
                <td><el-input v-model="records.v311"></el-input></td>

                <td colspan="2"
                    style=""><el-input v-model="records.v312"></el-input>
                </td>
            </tr>
            <tr height="21" style="height:15.75pt;">
                <td class="xl95" height="21" colspan="2"
                    style="height:15.75pt;">1010
                </td>
                <td><el-input v-model="records.v313"></el-input></td>
                <td><el-input v-model="records.v314"></el-input></td>

                <td colspan="3"
                    style="">同步时钟屏
                </td>
                <td><el-input v-model="records.v315"></el-input></td>
                <td><el-input v-model="records.v316"></el-input></td>

                <td colspan="2" style=""><el-input v-model="records.v317"></el-input>
                </td>
                <td colspan="3"
                    style="">110kV#1主变保护智能终端
                </td>
                <td><el-input v-model="records.v318"></el-input></td>
                <td><el-input v-model="records.v319"></el-input></td>

                <td colspan="2"
                    style=""><el-input v-model="records.v320"></el-input>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'patrolAssignmentMainSubstation',
    props: ['assignmentId'],
    data() {
        return {
            dataForm:{
                assignmentId:'',
                recordContent:'',
            },
            records: {
                v1: '',
                v2: '',
                v3: '',
                v4: '',
                v5: '',
                v6: '',
                v7: '',
                v8: '',
                v9: '',
                v10: '',
                v11: '',
                v12: '',
                v13: '',
                v14: '',
                v15: '',
                v16: '',
                v17: '',
                v18: '',
                v19: '',
                v20: '',
                v21: '',
                v22: '',
                v23: '',
                v24: '',
                v25: '',
                v26: '',
                v27: '',
                v28: '',
                v29: '',
                v30: '',
                v31: '',
                v32: '',
                v33: '',
                v34: '',
                v35: '',
                v36: '',
                v37: '',
                v38: '',
                v39: '',
                v40: '',
                v41: '',
                v42: '',
                v43: '',
                v44: '',
                v45: '',
                v46: '',
                v47: '',
                v48: '',
                v49: '',
                v50: '',
                v51: '',
                v52: '',
                v53: '',
                v54: '',
                v55: '',
                v56: '',
                v57: '',
                v58: '',
                v59: '',
                v60: '',
                v61: '',
                v62: '',
                v63: '',
                v64: '',
                v65: '',
                v66: '',
                v67: '',
                v68: '',
                v69: '',
                v70: '',
                v71: '',
                v72: '',
                v73: '',
                v74: '',
                v75: '',
                v76: '',
                v77: '',
                v78: '',
                v79: '',
                v80: '',
                v81: '',
                v82: '',
                v83: '',
                v84: '',
                v85: '',
                v86: '',
                v87: '',
                v88: '',
                v89: '',
                v90: '',
                v91: '',
                v92: '',
                v93: '',
                v94: '',
                v95: '',
                v96: '',
                v97: '',
                v98: '',
                v99: '',
                v100: '',
                v101: '',
                v102: '',
                v103: '',
                v104: '',
                v105: '',
                v106: '',
                v107: '',
                v108: '',
                v109: '',
                v110: '',
                v111: '',
                v112: '',
                v113: '',
                v114: '',
                v115: '',
                v116: '',
                v117: '',
                v118: '',
                v119: '',
                v120: '',
                v121: '',
                v122: '',
                v123: '',
                v124: '',
                v125: '',
                v126: '',
                v127: '',
                v128: '',
                v129: '',
                v130: '',
                v131: '',
                v132: '',
                v133: '',
                v134: '',
                v135: '',
                v136: '',
                v137: '',
                v138: '',
                v139: '',
                v140: '',
                v141: '',
                v142: '',
                v143: '',
                v144: '',
                v145: '',
                v146: '',
                v147: '',
                v148: '',
                v149: '',
                v150: '',
                v151: '',
                v152: '',
                v153: '',
                v154: '',
                v155: '',
                v156: '',
                v157: '',
                v158: '',
                v159: '',
                v160: '',
                v161: '',
                v162: '',
                v163: '',
                v164: '',
                v165: '',
                v166: '',
                v167: '',
                v168: '',
                v169: '',
                v170: '',
                v171: '',
                v172: '',
                v173: '',
                v174: '',
                v175: '',
                v176: '',
                v177: '',
                v178: '',
                v179: '',
                v180: '',
                v181: '',
                v182: '',
                v183: '',
                v184: '',
                v185: '',
                v186: '',
                v187: '',
                v188: '',
                v189: '',
                v190: '',
                v191: '',
                v192: '',
                v193: '',
                v194: '',
                v195: '',
                v196: '',
                v197: '',
                v198: '',
                v199: '',
                v200: '',
                v201: '',
                v202: '',
                v203: '',
                v204: '',
                v205: '',
                v206: '',
                v207: '',
                v208: '',
                v209: '',
                v210: '',
                v211: '',
                v212: '',
                v213: '',
                v214: '',
                v215: '',
                v216: '',
                v217: '',
                v218: '',
                v219: '',
                v220: '',
                v221: '',
                v222: '',
                v223: '',
                v224: '',
                v225: '',
                v226: '',
                v227: '',
                v228: '',
                v229: '',
                v230: '',
                v231: '',
                v232: '',
                v233: '',
                v234: '',
                v235: '',
                v236: '',
                v237: '',
                v238: '',
                v239: '',
                v240: '',
                v241: '',
                v242: '',
                v243: '',
                v244: '',
                v245: '',
                v246: '',
                v247: '',
                v248: '',
                v249: '',
                v250: '',
                v251: '',
                v252: '',
                v253: '',
                v254: '',
                v255: '',
                v256:'',
                v257:'',
                v258:'',
                v259:'',
                v260:'',
                v261:'',
                v262:'',
                v263:'',
                v264:'',
                v265:'',
                v266:'',
                v267:'',
                v268:'',
                v269:'',
                v270:'',
                v271:'',
                v272:'',
                v273:'',
                v274:'',
                v275:'',
                v276:'',
                v277:'',
                v278:'',
                v279:'',
                v280:'',
                v281:'',
                v282:'',
                v283:'',
                v284:'',
                v285:'',
                v286:'',
                v287:'',
                v288:'',
                v289:'',
                v290:'',
                v291:'',
                v292:'',
                v293:'',
                v294:'',
                v295:'',
                v296:'',
                v297:'',
                v298:'',
                v299:'',
                v300:'',
                v301:'',
                v302:'',
                v303:'',
                v304:'',
                v305:'',
                v306:'',
                v307:'',
                v308:'',
                v309:'',
                v310:'',
                v311:'',
                v312:'',
                v313:'',
                v314:'',
                v315:'',
                v316:'',
                v317:'',
                v318:'',
                v319:'',
                v320:'',
            }
        };
    },
    created() {
        this.dataForm.assignmentId=this.assignmentId;
        this.init();
    },
    methods: {
        init() {
            this.$client.getPatrolAssignmenMainStationInfo(this.assignmentId).then(({data})=>{
                if(data) {
                    this.dataForm = data;
                    this.records = JSON.parse(data.recordContent);
                }
            })
        },
        getFormData(){
            return this.records;
        }
    }
};
</script>

<style lang="less">

.substation-record {
    overflow: auto;
    height: 600px;

    td {
        border: 1px solid #2749a5;
        padding-left: 5px;
        text-align: center;

        input ,textarea{
            border: none !important;
        }
    }
}
</style>
