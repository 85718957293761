<template>
  <el-dialog
      width="1200px"
      :title="title"
    :close-on-click-modal="false"
    :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
               :disabled="disabled"
               label-width="120px" label-position="top">
          <el-row :gutter="20">
              <el-col :span="8">
                  <el-form-item label="作业任务：" prop="assignmentName">
                      <el-input v-model="dataForm.assignmentName" placeholder="作业任务"></el-input>
                  </el-form-item>
              </el-col>
              <el-col :span="8">
                  <el-form-item label="作业开始时间：" prop="startTime">
                      <el-date-picker
                          v-model="dataForm.startTime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          type="datetime"
                          placeholder="选择日期时间"
                          align="right">
                      </el-date-picker>
                  </el-form-item>
              </el-col>
              <el-col :span="8" v-if="dataForm.patrolType==1||dataForm.patrolType==3">
                  <el-form-item label="作业地点：" prop="assignmentAddr">
                      <el-input v-model="dataForm.assignmentAddr" placeholder="作业地点"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="20" v-if="dataForm.patrolType==1">
              <el-col :span="8">
                  <el-form-item label="设备型号：" prop="equipmentModel">
                      <el-input v-model="dataForm.equipmentModel" placeholder="设备型号"></el-input>
                  </el-form-item>
              </el-col>
              <el-col :span="8">
              <el-form-item label="投运日期：" prop="commissionDate">
                  <el-date-picker
                      v-model="dataForm.commissionDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期"
                      align="right">
                  </el-date-picker>
              </el-form-item>
              </el-col>
              <el-col :span="8">
              <el-form-item label="生产厂家：" prop="manufacturer">
                  <el-input v-model="dataForm.manufacturer" placeholder="生产厂家"></el-input>
              </el-form-item>
              </el-col>
          </el-row>

          <el-form-item label="作业前准备：" prop="beforePrepare">
              <el-input type="textarea" :rows="5" v-model="dataForm.beforePrepare" placeholder="作业前准备"
                        readOnly></el-input>
          </el-form-item>
          <el-form-item label="作业风险评估：" prop="riskEvaluation">
              <el-input type="textarea" :rows="5" v-model="dataForm.riskEvaluation" placeholder="作业风险评估"
                        readOnly></el-input>
          </el-form-item>
          <el-form-item label="作业工序："  prop="patrolAssignmentItems">
              <div id="form_item"  class="ui-border--top ui-border--right ui-border--left menu-content">
                  <div class="ui-layout-flex ui-border--bottom">
                      <div class="menu-head1">
                          序号
                      </div>
                      <div class="menu-head2 ui-border--left">
                          关键作业工序
                      </div>
                      <div class="menu-head3 ui-border--left">
                          工艺标准
                      </div>
                  </div>
              <el-checkbox-group v-model="checkedItems" @change="handleCheckedGroupChange">
                  <div class="ui-layout-flex" v-for="(group,index) in patrolAssignmentItems" :key="group.id">
                      <div class="menu-item0 ui-border--bottom ui-border--right">
                          {{index+1}}
                      </div>
                      <div class="menu-item1 ui-border--bottom ui-border--right">
                          <el-checkbox :label="group.id" :name="'group_'+group.id" :ref="'group_'+group.id"  @change="selectGroup(group)">
                              {{group.groupName}}
                          </el-checkbox>
                      </div>
                      <div class="ui-layout-flex ui-layout-flex--column menu-item2">
                          <el-checkbox-group v-model="group.checkedItems" @change="handleCheckedItemChange(group)">
                              <div v-for="item in group.items" :key="item.id" class="menu-item3 ui-border--bottom">
                                  <el-checkbox  :label="item.id" :ref="'item_'+item.id" :name="'item_'+item.id"   @change="selectItem(item,null,1)">
                                      {{item.itemName}}
                                  </el-checkbox>
                              </div>
                          </el-checkbox-group>
                      </div>
                  </div>
              </el-checkbox-group>
              </div>
          </el-form-item>
          <el-form-item label="变电站设备巡检记录：" v-if="dataForm.patrolType==3">
              <patrol-assignment-main-substation ref="assignmentSubstation" :assignmentId="dataForm.id"></patrol-assignment-main-substation>
          </el-form-item>
          <el-form-item label="作业结论：" prop="patrolResult">
              <el-radio-group v-model="dataForm.patrolResult">
                  <el-radio :key="item.id" :label="item.value" v-for="item in patrolResults">{{item.name}}</el-radio>
              </el-radio-group>
          </el-form-item>
          <el-form-item label="异常详细：">
            <el-card v-for="(item,index) in riskyDetails" :key="index" class="risky-detail-card">
                <el-button v-if="!disabled" icon="el-icon-close" circle class="remove-detail" @click="removeRiskyDetail(index)"></el-button>
                <el-row :gutter="24">
                    <el-col :span="8">
                        <el-form-item label="类型：">
                            <el-select v-model="item.type" clearable placeholder="请选择">
                                <el-option
                                    v-for="sitem in expTypes"
                                    :key="sitem.value"
                                    :label="sitem.name"
                                    :value="sitem.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="等级：">
                            <el-select v-model="item.level" clearable placeholder="请选择">
                                <el-option
                                    v-for="sitem in expLevels"
                                    :key="sitem.value"
                                    :label="sitem.name"
                                    :value="sitem.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="描述：">
                            <el-input v-model="item.description" placeholder="异常描述"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-button v-if="!disabled" @click="addRiskyDetail" icon="el-icon-plus" style="margin: 20px 0 10px;">新增异常</el-button>
          </el-form-item>
          <el-form-item label="作业成员：" prop="assignmentUsers">
              <el-select v-model="dataForm.assignmentUsers" multiple clearable placeholder="请选择">
                  <el-option
                      v-for="item in assignmentUserList"
                      :key="item.userId"
                      :label="item.realName"
                      :value="item.userId">
                  </el-option>
              </el-select>
          </el-form-item>
          <div v-if="dataForm.patrolType==2">
              <el-form-item label="异常设备标记：" prop="anomalyEquipments">
                  <el-select v-model="aEquipments" multiple clearable placeholder="请选择" @change="selectEquipment">
                      <el-option
                          v-for="item in equipmentList"
                          :key="item.id"
                          :label="item.equipmentName"
                          :value="item.id">
                      </el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="异常说明：" prop="anomalyAdvise">
                  <el-input type="textarea" :rows="5" v-model="dataForm.anomalyAdvise" placeholder="异常说明"></el-input>
              </el-form-item>
          </div>
          <el-form-item label="需补充或完善作业风险数据的建议：" prop="riskAdvise">
              <el-input type="textarea" :rows="5" v-model="dataForm.riskAdvise"
                        placeholder="需补充或完善作业风险数据的建议"></el-input>
          </el-form-item>
          <el-form-item label="完善作业指导书的建议：" prop="complementAdvise">
              <el-input type="textarea" :rows="5" v-model="dataForm.complementAdvise"
                        placeholder="完善作业指导书的建议"></el-input>
          </el-form-item>
      </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs';
import PatrolAssignmentMainSubstation from '@/pages/patrol/patrolAssignmentMainSubstation';
export default {
    components: {PatrolAssignmentMainSubstation},
    props: ['patrolResults', 'flawLevels', 'patrolTypes', 'expTypes', 'expLevels'],
    data () {
        return {
            visible: false,
            disabled: false,
            checkAll: false,
            isIndeterminate: false,
            checkedItems: [],
            equipmentList: [],
            patrolAssignmentItems: [],
            assignmentUserList: [],
            aEquipments: [],
            anomalyEquipments: [],
            riskyDetails: [],
            riskyItems: new Set(),
            title: '巡检作业指导书',
            dataForm: {
                id: 0,
                patrolId: '',
                assignmentNumber: '',
                patrolType: '',
                maintenanceUnit: '',
                maintenanceUnitId: '',
                maintenanceOwner: '',
                maintenanceOwnerId: '',
                patrolOwner: '',
                patrolOwnerId: '',
                patrolTime: '',
                assignmentName: '',
                assignmentAddr: '',
                manufacturer: '',
                equipmentModel: '',
                commissionDate: '',
                startTime: '',
                endTime: '',
                beforePrepare: '',
                riskEvaluation: '',
                patrolResult: '',
                flawLevel: '',
                patrolResultNote: '',
                riskAdvise: '',
                complementAdvise: '',
                assignmentUsers: '',
                anomalyEquipments: '',
                anomalyAdvise: '',
                patrolAssignmentItems: [],
                assignmentSubstation: ''
            },
            dataRule: {
                assignmentName: [
                    {required: true, message: '作业任务不能为空', trigger: 'blur'}
                ],
                assignmentAddr: [
                    {required: true, message: '作业地点不能为空', trigger: 'blur'}
                ],
                startTime: [
                    {required: true, message: '作业开始时间不能为空', trigger: 'blur'}
                ],
                equipmentModel: [
                    {required: true, message: '设备型号不能为空', trigger: 'blur'}
                ],
                beforePrepare: [
                    {required: true, message: '作业前准备不能为空', trigger: 'blur'}
                ],
                riskEvaluation: [
                    {required: true, message: '作业风险评估不能为空', trigger: 'blur'}
                ],
                patrolResultNote: [
                    {required: true, message: '作业结论说明不能为空', trigger: 'blur'}
                ],
                assignmentUsers: [
                    {required: true, message: '作业成员不能为空', trigger: 'blur'}
                ],
                patrolResult: [
                    {required: true, message: '作业结论不能为空', trigger: 'blur'}
                ],
                flawLevel: [
                    {required: true, message: '缺陷等级不能为空', trigger: 'blur'}
                ],
                status: [
                    {required: true, message: '状态不能为空', trigger: 'blur'}
                ],
            }
        };
    },
    methods: {
        handleCheckAllChange(val) {
            this.isIndeterminate = false;
        },
        handleCheckedGroupChange(value) {
            console.log('触发：0');
        },
        handleCheckedItemChange(group) {
            console.log('触发：1');
            if (group.checkedItems.length === 0) {
                this.checkedItems.splice(this.checkedItems.indexOf(group.id), 1);
            }
        },
        removeRiskyDetail(index) {
            this.riskyDetails.splice(index, 1);
        },
        addRiskyDetail() {
            this.riskyDetails.push({
                id: '',
                assignmentId: this.dataForm.id,
                type: '',
                level: '',
                description: ''
            });
        },
        // 处理风险项
        handleRiskyItem() {
            // if (this.riskyItems.size > 0) {
            //     this.dataForm.patrolResult = 'RISKY';
            // } else {
            //     this.dataForm.patrolResult = 'NORMAL';
            // }
            // let riskys = [...this.riskyItems].map(item => '【异常】' + item.itemName).join('\n');
            // this.dataForm.patrolResultNote = riskys;
        },
        selectGroup(group) {
            let isChecked = !this.$refs['group_' + group.id][0].isChecked;
            let checkedItems = [];
            for (let item of group.items) {
                if (isChecked) {
                    checkedItems.push(item.id);
                }
                this.selectItem(item, isChecked);
            }
            group.checkedItems = checkedItems;
        },
        selectItem(item, isChecked, click) {
            if (click) {isChecked = !this.$refs['item_' + item.id][0].isChecked;}
            if (isChecked) {
                item.itemStatus = 1;
                this.checkedItems.push(item.groupId);
                this.riskyItems.delete(item);
            } else {
                item.itemStatus = 0;
                this.riskyItems.add(item);
                this.checkedItems.splice(this.checkedItems.indexOf(item.groupId), 1);
            }
            this.handleRiskyItem();
        },
        loadSelectedItem(data) {
            for (let group of data) {
                let items = group.items.filter(item => item.itemStatus).map(item => {
                    this.checkedItems.push(group.id);
                    return item.id;
                });
                group.checkedItems = items;
            }
            return data;
        },
        init (row) {
            this.dataForm.id = row ? row.id : 0;
            this.visible = true;
            if (row.patrolType === 2) {
                this.$client.getPowerGridsOffspringEquipments(row.patrolEquipmentId).then((res) => {
                    this.equipmentList = res.data;
                });
            }
            for (let item of this.patrolTypes) {
                if (row.patrolType === item.value) {
                    this.title = item.name + '作业指导书';
                }
            }
            this.$client.getPatrolAssignmenItemList({patrolType: row.patrolType, assignmentId: row.id}).then((res) => {
                this.patrolAssignmentItems = this.loadSelectedItem(res.data);
            });

            this.$client.getUserByOrgId(row.maintenanceUnitId).then((res) => {
                this.assignmentUserList = res.data;
            });

            this.$client.getpatrolAssignmentExpDetailList({assignmentId: row.id}).then((resp) => {
                this.riskyDetails = resp.data.records.map(e => {
                    e.level += '';
                    return e;
                });
            });

            this.$nextTick(() => {
                this.$refs.dataForm.resetFields();
                this.riskyItems = new Set();
                if (this.dataForm.id) {
                    this.dataForm = {...row};
                    if (!this.dataForm.startTime) {
                        this.dataForm.startTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
                    }
                    if (this.dataForm.assignmentUsers) {
                        this.dataForm.assignmentUsers = JSON.parse(this.dataForm.assignmentUsers);
                    }
                    if (this.dataForm.anomalyEquipments) {
                        this.dataForm.anomalyEquipments = JSON.parse(this.dataForm.anomalyEquipments);
                        this.anomalyEquipments = this.dataForm.anomalyEquipments.map(item => item.id);
                    }
                    this.disabled = this.dataForm.status === 1 ? true : false;
                    if (this.dataForm.status === 0) {this.dataForm.patrolResult = 'NORMAL';}
                }
            });
        },
        selectEquipment(val) {
            let anomalyEquipments = [];
            for (let item of this.equipmentList) {
                if (val.indexOf(item.id) > -1) {
                    anomalyEquipments.push({
                        id: item.id,
                        equipmentId: item.equipmentId,
                        equipmentMainCategory: item.equipmentMainCategory
                    });
                }
            }
            this.anomalyEquipments = anomalyEquipments;
        },
        buildItems() {
            let items = [];
            for (let group of this.patrolAssignmentItems) {
                items = items.concat(group.items);
            }
            return items;
        },
        // 表单提交
        dataFormSubmit () {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    this.$confirm('确定提交吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.dataForm.patrolAssignmentItems = this.buildItems();
                        this.dataForm.assignmentUsers = JSON.stringify(this.dataForm.assignmentUsers);
                        this.dataForm.anomalyEquipments = JSON.stringify(this.anomalyEquipments);
                        this.dataForm.assignmentSubstation = this.$refs.assignmentSubstation ? JSON.stringify(this.$refs.assignmentSubstation.getFormData()) : '';
                        this.dataForm.patrolAssignmentExpDetails = this.riskyDetails;
                        const submit = this.dataForm.id ? this.$client.updatePatrolAssignment : this.$client.savePatrolAssignment;
                        submit(this.dataForm).then((data) => {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500
                            });
                            this.visible = false;
                            this.$emit('refreshDataList', 1);
                        });
                    });
                }
            });
        }
    }
};
</script>
<style lang="less">
.risky-detail-card{
    /deep/ .el-card__body{
        position: relative !important;
    }
    .remove-detail{
        position: absolute;
        right: 14px;
        top: 14px;
        z-index: 999;
    }
}
.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner{
    background-color: white !important;
}
.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
    border-color: black !important;
}
.menu-content {
    margin-top: 5px;
    height: 400px;
    position: relative;
    overflow-y: auto;
    .menu-head1,.menu-head2,.menu-head3{
        text-align: center;
        font-weight: bold;
        font-size: 16px;
    }
    .menu-item0{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }
    .menu-head1,.menu-item0{
        width: 5%;
    }
    .menu-head2,.menu-item1{
        width: 28%;
    }
    .menu-head3,.menu-item2{
        width: 66%;
    }
    .el-checkbox {
        white-space: normal !important;
        display: flex;
        align-items: center;
    }
    .menu-item1, .menu-item3{
        padding: 10px;
        display: flex;
        align-items: center;
    }
}
</style>
